import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'app/providers/StoreProvider/config/rootReducer'
import { stringRandom } from 'shared/tools/stringRandom'
import { notifications } from '@mantine/notifications'
import { IconCheck } from '@tabler/icons-react'
import { StateScheme } from 'app/providers/StoreProvider/config/StateScheme'
import { AxiosError } from 'axios'
import { $api } from '@/shared/api/api'
import { uploadCertificateActions } from '@/features/uploadCertificate'
import { certApi } from '@/shared/api/certApi'

export const uploadFileCertification = createAsyncThunk<
    any,
    { file: File; uid: string },
    { state: RootState; rejectValue: string }
>('addCertification/uploadFileCertification', async ({ file, uid }, thunkAPI) => {
    const chunkSize = 5 * 1024 * 1024 // 5 MB - Здесь указан размер на который будет нарезаться файл
    const totalBytes = file.size // получаю из данных файла его размер
    let start = 0 // начальная загрузка, стартовый размер
    let end = Math.min(start + chunkSize, totalBytes) // результат последней загрузки, поскольку в конце всегда будет разный остаток поэтому через Math.min
    const key = stringRandom()

    notifications.show({
        id: 'load-data',
        loading: true,
        title: 'Сохраняем ваш файл',
        message: 'Отправляем Ваш файл на сервер, пожалуйста не покидайте страницу',
        autoClose: false,
        withCloseButton: false,
    })

    try {
        while (start < totalBytes) {
            //начинаем цикл
            const formData = new FormData() //создаем formData
            formData.append('uid', uid) //записываем в formData uid track
            formData.append('key', key)
            const chunk = file.slice(start, end)
            formData.append('input_file', chunk, file.name)

            const state = thunkAPI.getState() as StateScheme

            const certUrl = state.urls.certificate

            await $api.post(`${certUrl}/api/v1/certificate/add_work_main_file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Range': `bytes ${start}-${end - 1}/${totalBytes}`,
                },
                onUploadProgress: function (progressEvent) {
                    if (progressEvent.total !== undefined) {
                        const percentCompleted = Math.round(((start + progressEvent.loaded) * 100) / totalBytes)
                        thunkAPI.dispatch(uploadCertificateActions.updateUploadProgress(percentCompleted))
                    }
                },
            })

            start = end
            end = Math.min(start + chunkSize, totalBytes)
        }

        thunkAPI.dispatch(certApi.util.invalidateTags([{ type: 'detail', id: uid }]))

        notifications.update({
            id: 'load-data',
            color: 'teal',
            title: 'Файл успешно загружен',
            message: 'Спасибо за предоставленый файл, можете переходить к оплате',
            icon: <IconCheck size="1rem" />,
            autoClose: 5000,
        })
    } catch (error) {
        let errorMessage = 'Произошла неизвестная ошибка'

        if (error && error instanceof AxiosError) {
            errorMessage = error.response?.data.message || error.message
        }

        console.log(errorMessage)
        return thunkAPI.rejectWithValue(errorMessage)
    }
})
