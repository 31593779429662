import { createSlice } from '@reduxjs/toolkit'
import { UserStateScheme } from '../types/userSchema'
import { userApi } from '../../api/userApi'

const initialState: UserStateScheme = {
    inited: false,
    userToken: '',
    isAuth: false,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(userApi.endpoints.initAuth.matchFulfilled, (state, { payload }) => {
                const { token } = payload.result

                state.inited = true
                state.userToken = token
                state.isAuth = true
            })
            .addMatcher(userApi.endpoints.initAuth.matchRejected, (state) => {
                state.inited = true
            })
            .addMatcher(userApi.endpoints.logoutUser.matchFulfilled, (state) => {
                state.userToken = ''
                state.isAuth = false
            })
    },
})

export const { actions: userActions } = userSlice
export const { reducer: userReducer } = userSlice
