import { memo, Suspense, useCallback } from 'react'
import { Route, Routes } from 'react-router-dom'
import { AppRoutesProps, routeConfig } from '../routeConfig/routeConfig'
import { ScrollToTop } from './ScrollToTop'
import { PageLoader } from '@/widgets/PageLoader'
import { RequireAuth } from '@/app/providers/router/ui/RequireAuth'

const AppRouter = () => {
    const renderWithWrapper = useCallback((route: AppRoutesProps) => {
        const element = <Suspense fallback={<PageLoader />}>{route.element}</Suspense>

        return (
            <Route
                key={route.path}
                path={route.path}
                element={route.isRegistered ? <RequireAuth roles={route.roles}>{element}</RequireAuth> : element}
            />
        )
    }, [])

    return (
        <div className={'page-wrapper'}>
            <ScrollToTop />
            <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>
        </div>
    )
}
export default memo(AppRouter)
