import { combineReducers } from '@reduxjs/toolkit'
import { StateScheme } from './StateScheme'
import { urlsReducer } from '../../InitializedProvider'
import { globalAlertModalReducer } from '@/widgets/GlobalAlertModal'
import { userReducer } from '@/entities/User'
import { authApi } from '@/shared/api/authApi'
import { certApi } from '@/shared/api/certApi'
import { certificateReducer } from '@/entities/Certificate'
import { uploadCertificateReducer } from '@/features/uploadCertificate'

const rootReducer = combineReducers({
    urls: urlsReducer,
    user: userReducer,
    certificate: certificateReducer,
    globalAlertModal: globalAlertModalReducer,
    uploadCertificate: uploadCertificateReducer,
    [certApi.reducerPath]: certApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
})

export type RootState = StateScheme
export default rootReducer
