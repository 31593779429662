import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { UploadCertificateStateScheme } from '../types/uploadCertificateSchema'
import { uploadImageCertification } from '../services/uploadImageCertification'
import { uploadFileCertification } from '../services/uploadFileCertification'
import { certificateApi } from '@/entities/Certificate/api/certificateApi'

const initialState: UploadCertificateStateScheme = {
    uploadFile: {
        error: '',
        isLoading: false,
        progress: 0,
        success: false,
    },
    uploadImage: {
        error: '',
        isLoading: false,
        success: false,
    },
}

export const uploadCertificateSlice = createSlice({
    name: 'uploadCertificate',
    initialState,
    reducers: {
        updateUploadProgress: (state, action: PayloadAction<number>) => {
            state.uploadFile.progress = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadImageCertification.pending, (state) => {
                state.uploadImage.isLoading = true
                state.uploadImage.error = undefined
            })
            .addCase(uploadImageCertification.fulfilled, (state) => {
                state.uploadImage.isLoading = false
            })
            .addCase(uploadImageCertification.rejected, (state, { payload }) => {
                state.uploadImage.isLoading = false
                state.uploadImage.error = payload
            })
            .addCase(uploadFileCertification.pending, (state) => {
                state.uploadFile.isLoading = true
                state.uploadFile.error = undefined
            })
            .addCase(uploadFileCertification.fulfilled, (state) => {
                state.uploadFile.isLoading = false
            })
            .addCase(uploadFileCertification.rejected, (state, { payload }) => {
                state.uploadFile.isLoading = false
                state.uploadFile.error = payload
            })

        builder.addMatcher(certificateApi.endpoints.getDetailDescription.matchFulfilled, (state, { payload }) => {
            console.log('ФАЙЛ', payload.result.main_file)
            if (!payload.result.main_file) {
                state.uploadFile.success = false
            } else {
                state.uploadFile.success = true
            }
            console.log('ИЗОБРАЖЕНИЕ', payload.result.main_file)
            if (!payload.result.image_file) {
                state.uploadImage.success = false
            } else {
                state.uploadImage.success = true
            }
        })
    },
})

export const { actions: uploadCertificateActions } = uploadCertificateSlice
export const { reducer: uploadCertificateReducer } = uploadCertificateSlice
