import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    navbar: {
        backgroundColor: 'transparent',
        color: theme.colors.gray[0],
        zIndex: 110,
    },
    navbarHeader: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        padding: '22px 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 140,
    },
    ava: {
        width: 41,
        height: 41,
        borderRadius: '50%',
    },
}))
