import { FC, memo } from 'react'
import { Box, Button, Center, FileButton, Loader, Text } from '@mantine/core'
import { useSelector } from 'react-redux'
import { useStyles } from './UploadCertificateImage.styles'
import { uploadImageCertification } from '../../model/services/uploadImageCertification'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'
import { useGlobalAlertModal } from '@/widgets/GlobalAlertModal'
import { useGetDetailDescription } from '@/entities/Certificate'
import { getUploadImage } from '@/features/uploadCertificate/model/selectors/getUploadImage/getUploadImage'

interface UploadCertificateImageProps {
    uid: string
}

export const UploadCertificateImage: FC<UploadCertificateImageProps> = memo((props) => {
    const { uid } = props
    const { classes } = useStyles()

    const { isLoading } = useSelector(getUploadImage)

    const { isLoading: isLoadingDetail } = useGetDetailDescription(uid)

    const dispatch = useAppDispatch()

    const { openGlobalAlert } = useGlobalAlertModal()

    const onChangeImage = async (file: File | null) => {
        if (file) {
            const imageType = file.type

            if (imageType === 'image/jpeg' || imageType === 'image/jpg' || imageType === 'image/png') {
                const image = new Image()
                image.src = URL.createObjectURL(file)
                image.onload = async () => {
                    if (image.naturalWidth === 1440 && image.naturalHeight === 1440) {
                        dispatch(
                            uploadImageCertification({
                                file: file,
                                uid: uid,
                            })
                        )
                    } else {
                        openGlobalAlert({
                            message: 'Размер изображения должен быть не менее 1440х1440px',
                            title: 'Неподходящие изображение',
                            type: 'error',
                        })
                    }
                }
            } else {
                openGlobalAlert({
                    message: 'Допустимы только изображения в формате jpeg/jpg/png',
                    title: 'Неподходящие изображение',
                    type: 'error',
                })
            }
        }
    }

    return (
        <div className={classes.uploadCertificateImage}>
            <Center>
                <Box pos={'relative'} maw={340}>
                    <Box className={classes.wrapperImage}>
                        {isLoading && (
                            <div className={classes.loader}>
                                <Loader />
                            </div>
                        )}
                        <img
                            alt=""
                            className={classes.img}
                            src={`https://certificate.yourtunes.net/api/v1/certificate/get_work_image/${uid}?timestamp=${new Date().getTime()}`}
                        />
                        <img src={'/assets/images/image-default.jpeg'} className={classes.imgDefault} alt={''} />
                    </Box>
                    <FileButton onChange={onChangeImage} accept="image/png,image/jpeg">
                        {(props) => (
                            <Button fullWidth mt={24} {...props}>
                                Выберите изображение
                            </Button>
                        )}
                    </FileButton>
                </Box>
            </Center>
            <Text color="gray" mt="md" fz={'sm'} ta={'justify'}>
                Депонирование Контента — один из способов защиты, который фиксирует наличие Контента, его существование
                на конкретный момент времени, внесение его в реестр конкретным лицом. Однако депонирование не
                гарантирует абсолютную защиту и является одним из доказательств наличия прав на Контент, которое
                воспринимается в совокупности с другими документами и может быть опровержено
            </Text>
        </div>
    )
})

UploadCertificateImage.displayName = 'UploadCertificateImage'
