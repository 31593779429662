import { useSelector } from 'react-redux'
import { ReactComponent as UserProfileSVG } from '@/shared/assets/icons/user-profile.svg'
import { ReactComponent as MyReleasesSVG } from '@/shared/assets/icons/my-releases.svg'
import { ReactComponent as DashboardSVG } from '@/shared/assets/icons/dashboard.svg'
import { ReactComponent as BalanceSVG } from '@/shared/assets/icons/card.svg'
import { ReactComponent as CertificationSVG } from '@/shared/assets/icons/certification.svg'
import { ReactComponent as RubleSVG } from '@/shared/assets/icons/ruble.svg'
import { ReactComponent as FaqSVG } from '@/shared/assets/icons/help.svg'
import { MenuItemType } from '@/widgets/Menu/model/types/menu'
import { getSiteUrl } from '@/app/providers/InitializedProvider'
import { getRouteCertificatesList } from '@/shared/consts/router'

export const useMainMenuItems = () => {
    const siteUrl = useSelector(getSiteUrl)

    const mainMenuItemsList: MenuItemType[] = [
        {
            text: 'Профиль',
            path: `${siteUrl}/ytnt/profile`,
            Icon: UserProfileSVG,
            inside: true,
        },
        {
            text: 'Мои релизы',
            path: `${siteUrl}/ytnt/releases`,
            Icon: MyReleasesSVG,
            inside: true,
        },
        {
            text: 'Статистика',
            path: `${siteUrl}/ytnt/statistics`,
            Icon: DashboardSVG,
            inside: true,
        },
        {
            text: 'Баланс',
            path: `${siteUrl}/ytnt/balance`,
            Icon: BalanceSVG,
            inside: true,
        },
        {
            text: 'Фин. аналитика',
            path: `${siteUrl}/ytnt/financial-analytics`,
            Icon: RubleSVG,
            inside: true,
        },
        {
            text: 'Сертификация',
            path: getRouteCertificatesList(),
            Icon: CertificationSVG,
        },
    ]

    return mainMenuItemsList
}
export const useSecondMenuItems = () => {
    const siteUrl = useSelector(getSiteUrl)

    const secondaryMenuItemsList: MenuItemType[] = [
        {
            text: 'Помощь',
            path: `${siteUrl}/ytnt/faq`,
            Icon: FaqSVG,
        },
    ]
    return secondaryMenuItemsList
}
