import { createAsyncThunk } from '@reduxjs/toolkit'
import { notifications } from '@mantine/notifications'
import { IconCheck } from '@tabler/icons-react'
import { AxiosError } from 'axios'
import { StateScheme } from '@/app/providers/StoreProvider/config/StateScheme'
import { RootState } from '@/app/providers/StoreProvider/config/rootReducer'
import { $api } from '@/shared/api/api'
import { certApi } from '@/shared/api/certApi'

export const uploadImageCertification = createAsyncThunk<
    any,
    { file: File; uid: string },
    { state: RootState; rejectValue: string }
>('addCertification/uploadImageCertification', async ({ file, uid }, thunkAPI) => {
    const formData = new FormData()
    formData.append('uid', uid)
    formData.append('input_file', file)

    const state = thunkAPI.getState() as StateScheme

    notifications.show({
        id: 'load-data',
        loading: true,
        title: 'Сохраняем изображение',
        message: 'Изображение Вашей обложки отправляется на сервер, не покидайте страницу',
        autoClose: false,
        withCloseButton: false,
    })

    const certificationUrl = state.urls.certificate

    try {
        const response = await $api.post(`${certificationUrl}/api/v1/certificate/add_work_image_file`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })

        thunkAPI.dispatch(certApi.util.invalidateTags([{ type: 'detail', id: uid }]))
        notifications.update({
            id: 'load-data',
            color: 'teal',
            title: 'Изображение успешно загружен',
            message: 'Спасибо за предоставленное изображение',
            icon: <IconCheck size="1rem" />,
            autoClose: 5000,
        })

        return response.data
    } catch (error) {
        let errorMessage = 'Произошла неизвестная ошибка'

        if (error && error instanceof AxiosError) {
            errorMessage = error.response?.data.message || error.message
        }

        console.log(errorMessage)
        return thunkAPI.rejectWithValue(errorMessage)
    }
})
