import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    exitButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 30,
    },
    exitBtn: {
        '&:hover': {
            background: theme.colors.gray[1],
        },
    },
    icon: {
        fill: theme.colors.gray[4],
    },
    navbarBtn: {
        '& .mantine-Button-root': {
            fontSize: theme.fontSizes.lg,
            color: theme.colors.gray[1],
            fontWeight: 400,
        },
        '&:hover .mantine-Button-root': {
            background: 'transparent',
            color: theme.colors.primary,
            textDecoration: 'underline',
        },
        '&:hover svg': {
            fill: theme.colors.primary,
        },
        '& svg': {
            marginRight: theme.spacing.lg,
        },
    },
}))
