import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { StateScheme } from '@/app/providers/StoreProvider/config/StateScheme'

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    const state: StateScheme = api.getState() as StateScheme
    const dynamicUrl = state.urls.certificate + '/api/v1' || 'https://dev-cert.yourtunes.net/api/v1'

    // Если args - строка, мы должны соединить ее с dynamicUrl
    const url = typeof args === 'string' ? `${dynamicUrl}${args}` : `${dynamicUrl}${args.url}`

    // Если args - объект, мы создаем новый объект с обновленным URL
    const adjustedArgs = typeof args === 'string' ? url : { ...args, url: url }

    return fetchBaseQuery({
        baseUrl: dynamicUrl,
        prepareHeaders: (headers) => {
            const token = state.user.userToken

            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },
    })(adjustedArgs, api, extraOptions)
}

export const certApi = createApi({
    reducerPath: 'certApi',
    baseQuery: dynamicBaseQuery,
    tagTypes: ['list', 'image', 'detail'],
    endpoints: () => ({}),
})
