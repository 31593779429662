import classNames from 'classnames'
import { FC } from 'react'
import { Text } from '@mantine/core'
import { useStyles } from './Sidebar.styles'
import { UserSidebarCard } from '@/entities/User'
import { MenuList } from '@/widgets/Menu'
import { useMainMenuItems, useSecondMenuItems } from '@/shared/configs/menuConfig/menuConfig'

interface SidebarProps {
    className?: string
}

export const Sidebar: FC<SidebarProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()

    const mainMenuItemsList = useMainMenuItems()
    const secondaryMenuItemsList = useSecondMenuItems()

    return (
        <div className={classNames(classes.sidebar, {}, [className])}>
            <UserSidebarCard />
            <MenuList className={classes.mainMenu} dataMenuItems={mainMenuItemsList} />
            <Text className={classes.secondaryTitle}>FAQ</Text>
            <MenuList className={classes.mainMenu} dataMenuItems={secondaryMenuItemsList} />
        </div>
    )
}
