import { FC, ReactNode, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MantineProvider } from '@mantine/core'
import { fetchUrls } from '../model/services/fetchUrls'
import { geUrlsIsLoading } from '../model/selectors/geUrlsIsLoading/geUrlsIsLoading'
import { PageLoader } from '@/widgets/PageLoader'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'

interface InitializedProviderProps {
    children: ReactNode
}

export const InitializedProvider: FC<InitializedProviderProps> = (props) => {
    const { children } = props

    const dispatch = useAppDispatch()

    const isLoading = useSelector(geUrlsIsLoading)

    useEffect(() => {
        dispatch(fetchUrls())
    }, [])

    if (isLoading) {
        return (
            <MantineProvider>
                <PageLoader />
            </MantineProvider>
        )
    }

    return <>{children}</>
}
