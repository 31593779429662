import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    uploadCertificateImage: {},
    wrapperImage: {
        width: 300,
        height: 300,
        border: `1px solid ${theme.colors.gray[2]}`,
        position: 'relative',
    },
    imgDefault: {
        width: 290,
        height: 290,
        position: 'absolute',
        inset: 5,
        zIndex: 0,
    },
    img: {
        zIndex: 100,
        width: 300,
        height: 300,
        position: 'absolute',
    },
    loader: {
        background: 'rgba(0,0,0,0.38)',
        width: 300,
        height: 300,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 200,
    },
}))
