import { FC, memo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './PaymentButton.styles'
import { getUploadFile } from '../../model/selectors/getUploadFile/getUploadFile'
import { getUploadImage } from '../../model/selectors/getUploadImage/getUploadImage'
import { usePayCertificate } from '../../api/uploadCertificateApi'
import { getRouteCertificateDescription } from '@/shared/consts/router'
import { NavigationButtons } from '@/shared/ui/NavigationButtons/NavigationButtons'

interface PaymentButtonProps {
    uid: string
}

export const PaymentButton: FC<PaymentButtonProps> = memo((props) => {
    const { uid } = props
    const { classes } = useStyles()

    const navigate = useNavigate()

    const [payCertificate, { isLoading }] = usePayCertificate()

    const { success: fileSuccess } = useSelector(getUploadFile)
    const { success: imageSuccess } = useSelector(getUploadImage)

    const goToDescription = () => {
        navigate(getRouteCertificateDescription(uid))
    }

    const handlerPay = async () => {
        try {
            const response = await payCertificate(uid).unwrap()
            window.location.href = response.result.link
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div>
            <NavigationButtons
                isNextDisabled={!fileSuccess || !imageSuccess}
                onPrev={goToDescription}
                isLoading={isLoading}
                onNext={handlerPay}
                textNext={'Оплатить'}
            />
        </div>
    )
})

PaymentButton.displayName = 'PaymentButton'
