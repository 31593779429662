import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    uploadCertificateFile: {},
    wrapperLoading: {
        height: 20,
        margin: '10px 0',
    },
    success: {
        background: theme.colors.green[2],
    },
    error: {
        background: theme.colors.red[3],
    },
    downloadWrapper: {
        flexGrow: 1,
        width: '100%',
    },
    iconSuccess: {
        color: theme.colors.green,
    },
    iconError: {
        color: theme.colors.red,
    },
}))
