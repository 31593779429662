import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    navigationButtons: {
        '& button': {
            maxWidth: 200,
            [theme.fn.smallerThan('xs')]: {
                maxWidth: 'none',
            },
        },
    },
    nextBtn: {
        [theme.fn.smallerThan('xs')]: {
            order: 1,
        },
    },
    prevBtn: {
        [theme.fn.smallerThan('xs')]: {
            order: 2,
        },
    },
}))
