import { GetImageResponse, PayCertificateResponse } from '../model/types/addCertification'
import { certApi } from '@/shared/api/certApi'

export const uploadCertificateApi = certApi.injectEndpoints({
    endpoints: (build) => ({
        payCertificate: build.mutation<PayCertificateResponse, string>({
            query: (uid) => ({
                url: `/certificate/get_pay_link/${uid}`,
                method: 'GET',
            }),
        }),
        getCertificateImage: build.query<any, string>({
            query: (uid) => ({
                url: `/certificate/get_work_image/${uid}`,
            }),
            providesTags: [{ type: 'image', id: 'all' }],
        }),
    }),
})

export const usePayCertificate = uploadCertificateApi.usePayCertificateMutation
export const useGetCertificateImage = uploadCertificateApi.useGetCertificateImageQuery
