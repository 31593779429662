import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './rootReducer'
import { authApi } from '@/shared/api/authApi'
import { certApi } from '@/shared/api/certApi'

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware).concat(certApi.middleware),
})

export type AppDispatch = typeof store.dispatch
