import classNames from 'classnames'
import { FC, ButtonHTMLAttributes, memo } from 'react'
import { useStyles } from './HamburgerButton.styles'
import { ReactComponent as HamburgerSVG } from '@/shared/assets/icons/hamburger-menu.svg'
import { ReactComponent as HamburgerOpenSVG } from '@/shared/assets/icons/hamburger-menu-open.svg'

interface HamburgerButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
    opened: boolean
}

export const HamburgerButton: FC<HamburgerButtonProps> = memo((props) => {
    const { className, opened, ...outherProps } = props

    const { classes } = useStyles()

    return (
        <button type="button" className={classNames(classes.hamburgerButton, {}, [className])} {...outherProps}>
            {opened ? <HamburgerOpenSVG /> : <HamburgerSVG />}
        </button>
    )
})

HamburgerButton.displayName = 'HamburgerButton'
