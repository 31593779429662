import { createSlice } from '@reduxjs/toolkit'
import { UrlsStateScheme } from '../types/urlsSchema'
import { fetchUrls } from '../services/fetchUrls'

const initialState: UrlsStateScheme = {
    urlsIsLoading: true,
    api: '',
    auth: '',
    site: '',
    api_old: '',
    track_file: '',
    ava_file: '',
    certificate: '',
}

export const urlsSlice = createSlice({
    name: 'urls',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUrls.fulfilled, (state, { payload }) => {
                state.urlsIsLoading = false
                state.api = payload.api
                state.site = payload.site
                state.api_old = payload.api_old
                state.certificate = payload.certificate
                state.auth = payload.auth
                state.track_file = payload.track_file
                state.ava_file = payload.ava_file
            })
            .addCase(fetchUrls.rejected, (state, action) => {
                state.urlsIsLoading = false
            })
    },
})

export const { actions: urlsActions } = urlsSlice
export const { reducer: urlsReducer } = urlsSlice
