import { RegisterUserRequest } from '../model/types/registerUserSchema'
import { certApi } from '@/shared/api/certApi'

export const registerUserApi = certApi.injectEndpoints({
    endpoints: (build) => ({
        registerUser: build.mutation<void, RegisterUserRequest>({
            query: (body) => ({
                url: '/certificate/create_new_udiar_user',
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const useRegisterUser = registerUserApi.useRegisterUserMutation
