import {
    CertificatesListResponse,
    CheckRegistrationCertificate,
    DetailDescriptionResponse,
} from '../model/types/certificateSchema'
import { certApi } from '@/shared/api/certApi'

export const certificateApi = certApi.injectEndpoints({
    endpoints: (build) => ({
        checkRegistration: build.query<CheckRegistrationCertificate, void>({
            query: () => ({
                url: '/certificate/check_udiar_user_registration',
            }),
        }),
        getDetailDescription: build.query<DetailDescriptionResponse, string>({
            query: (uid) => ({
                url: `/certificate/get_work_info/${uid}`,
            }),
            providesTags: (result, error, uid) => [{ type: 'detail', id: uid }],
        }),
        getCertificatesList: build.query<CertificatesListResponse, void>({
            query: () => ({
                url: '/certificate/get_works_list',
            }),
            providesTags: [{ type: 'list', id: 'all' }],
        }),
        deleteCertificate: build.mutation<any, string>({
            query: (uid) => ({
                url: `/certificate/delete_work/${uid}`,
                method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'list', id: 'all' }],
        }),
    }),
})

export const useCheckRegistration = certificateApi.useCheckRegistrationQuery
export const useLazyGetDetailDescription = certificateApi.useLazyGetDetailDescriptionQuery
export const useGetDetailDescription = certificateApi.useGetDetailDescriptionQuery
export const useGetCertificatesList = certificateApi.useGetCertificatesListQuery

export const useDeleteCertificate = certificateApi.useDeleteCertificateMutation
