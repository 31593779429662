import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    sidebar: {
        background: 'linear-gradient(177.15deg, #333333 -2.6%, #252525 100.5%)',
        minHeight: '100vh',
        width: 286,
        borderTopRightRadius: theme.radius.lg,
        borderBottomRightRadius: theme.radius.lg,
        color: theme.colors.gray[0],
        paddingTop: 60,
        flexShrink: 0,
    },
    mainMenu: {
        marginTop: 45,
        marginLeft: 45,
    },
    secondaryTitle: {
        marginLeft: 45,
    },
}))
