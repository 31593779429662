import { UserTokenResponse } from '../model/types/userSchema'
import { authApi } from '@/shared/api/authApi'

export const userApi = authApi.injectEndpoints({
    endpoints: (build) => ({
        initAuth: build.query<UserTokenResponse, void>({
            query: () => ({
                url: '/get-token',
            }),
        }),
        logoutUser: build.mutation<void, void>({
            query: () => ({
                url: '/logout',
                method: 'GET',
            }),
        }),
    }),
})

export const useInitAuth = userApi.useInitAuthQuery
export const useLogoutUser = userApi.useLogoutUserMutation
