import classNames from 'classnames'
import { useMediaQuery } from '@mantine/hooks'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { GlobalStyle } from './styles/GlobalStyle'
import { ThemeProvider } from './styles/ThemeProvider'
import { AppRouter } from './providers/router'
import { getAuthUrl } from './providers/InitializedProvider'
import { PageLoader } from '@/widgets/PageLoader'
import { InternetConnectionChecker } from '@/widgets/InternetConnectionChecker'
import { GlobalAlertModal } from '@/widgets/GlobalAlertModal/ui/GlobalAlertModal'
import { Sidebar } from '@/widgets/Sidebar'
import { Navbar } from '@/widgets/Navbar'
import { getInited, useInitAuth } from '@/entities/User'

const App = () => {
    const navbarOrSidebar = useMediaQuery('(min-width: 62em)')

    const inited = useSelector(getInited)
    const authUrl = useSelector(getAuthUrl)

    const { error } = useInitAuth()

    if (!inited) {
        return (
            <ThemeProvider>
                <PageLoader />
            </ThemeProvider>
        )
    }

    //Если не авторизован перенаправляем на авторизацию
    if (error) {
        const currentUrl = window.location.href
        if ('data' in error) window.location.href = `${authUrl}?returnUrl=${currentUrl}`
    }

    return (
        <ThemeProvider>
            <GlobalStyle />
            <div className={classNames('app', {}, [])}>
                {/* Глобальное модальное окно с ошибками, для взаимодействия используйте redux */}
                <GlobalAlertModal />
                <div className="content-page">
                    {navbarOrSidebar ? <Sidebar /> : <Navbar />}
                    <InternetConnectionChecker>
                        <AppRouter />
                    </InternetConnectionChecker>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default memo(App)
