import { Box, FileInput, Group, HoverCard, Progress, Stack, Text } from '@mantine/core'
import { FC, memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { IconCircleXFilled, IconCircleCheckFilled } from '@tabler/icons-react'
import { useStyles } from './UploadCertificateFile.styles'
import { uploadFileCertification } from '../../model/services/uploadFileCertification'
import { getUploadFile } from '../../model/selectors/getUploadFile/getUploadFile'
import { useGlobalAlertModal } from '@/widgets/GlobalAlertModal'
import { useAppDispatch } from '@/shared/hooks/useAppDispatch/useAppDispatch'
import { useGetDetailDescription } from '@/entities/Certificate'

interface UploadCertificateFileProps {
    uid: string
}

const fileType = /(\.mp3|\.wav)$/i

export const UploadCertificateFile: FC<UploadCertificateFileProps> = memo((props) => {
    const { uid } = props
    const { classes } = useStyles()

    const dispatch = useAppDispatch()
    const { openGlobalAlert } = useGlobalAlertModal()

    const { data: detailInfo } = useGetDetailDescription(uid)

    const { success, isLoading: isLoadingFile, progress, error } = useSelector(getUploadFile)

    const [file, setFile] = useState<File | null>(null)

    const onChangeFile = (newFile: File | null) => {
        if (newFile && newFile.size > 30 * 1024 * 1024) {
            openGlobalAlert({
                message: 'Файл слишком большой. Максимальный размер файла 30MB.',
                title: 'Неподходящий файл',
                type: 'error',
            })
            return
        }

        if (newFile && !fileType.exec(newFile.name)) {
            openGlobalAlert({
                message: 'Недопустимый тип файла. Только .mp3/.wav файлы разрешены.',
                title: 'Неподходящий файл',
                type: 'error',
            })
            return
        }

        setFile(newFile)
        if (newFile) {
            dispatch(
                uploadFileCertification({
                    file: newFile,
                    uid: uid,
                })
            )
        }
    }

    const mods = {
        [classes.error]: error,
        [classes.success]: success,
    }

    return (
        <Box maw={400}>
            <Text fz={'sm'} mb={5}>
                {success ? 'Файл загружен' : 'Выберите файл'}
            </Text>
            <Group noWrap align={'center'}>
                <div className={classes.downloadWrapper}>
                    <FileInput
                        withAsterisk
                        placeholder={success ? detailInfo?.result.original_name : 'Выберите файл'}
                        value={file}
                        onChange={onChangeFile}
                        error={error && 'Ошибка загрузки'}
                    />
                </div>
                <HoverCard width={'auto'} shadow="md">
                    <HoverCard.Target>
                        {success ? (
                            <IconCircleCheckFilled className={classes.iconSuccess} />
                        ) : (
                            <IconCircleXFilled className={classes.iconError} />
                        )}
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                        {success ? <Text size="sm">Файл загружен!</Text> : <Text size="sm">Загрузите файл!</Text>}
                    </HoverCard.Dropdown>
                </HoverCard>
            </Group>

            <div className={classes.wrapperLoading}>{isLoadingFile && <Progress value={progress} />}</div>

            <Stack spacing={5}>
                <Text color="gray" fz="sm">
                    Требования к файлу:
                </Text>
                <Text color="gray" fz="sm">
                    1. Тип файла .mp3 или .wav
                </Text>
                <Text color="gray" fz="sm">
                    2. Размер не должен превышать 30MB
                </Text>
            </Stack>
        </Box>
    )
})

UploadCertificateFile.displayName = 'UploadCertificateFile'
