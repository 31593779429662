import { FC, memo } from 'react'
import classNames from 'classnames'
import { Alert, Text } from '@mantine/core'
import { useStyles } from './AlertYt.styles'

interface AlertYtProps {
    className?: string
    type?: 'success' | 'error' | 'info'
    title?: string
    children?: string
    onClose?: (value: string) => void
    withCloseButton?: boolean
    variant?: 'light' | 'filled' | 'outline'
    maw?: number
}

export const AlertYt: FC<AlertYtProps> = memo((props) => {
    const {
        className,
        type = 'error',
        title,
        children,
        onClose,
        withCloseButton = true,
        variant = 'light',
        maw,
    } = props
    const { classes } = useStyles()

    const renderTitle = () => {
        switch (type) {
            case 'error':
                return 'Ошибка'
            case 'info':
                return 'Внимание'
            case 'success':
                return 'Успешно'
        }
    }

    const renderColor = () => {
        switch (type) {
            case 'error':
                return 'red'
            case 'info':
                return 'blue'
            case 'success':
                return 'green'
        }
    }

    return (
        <Alert
            className={classNames(classes.alert, {}, className)}
            title={title ?? renderTitle()}
            color={renderColor()}
            withCloseButton={withCloseButton}
            onClose={onClose && (() => onClose(''))}
            variant={variant}
            radius={'lg'}
            maw={maw}
        >
            <Text>{children}</Text>
        </Alert>
    )
})

AlertYt.displayName = 'AlertYt'
