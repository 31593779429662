import { FC, memo, useState } from 'react'
import { Button, Grid, Stack, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useStyles } from './RegistrationUserForm.styles'
import { RegisterUserRequest } from '../model/types/registerUserSchema'
import { CountrySelect } from '@/entities/Country'
import { useRegisterUser } from '@/features/registerUser/api/registerUserApi'
import { AlertYt } from '@/shared/ui/AlertYt/AlertYt'

interface RegistrationUserFormProps {
    className?: string
}

export const RegistrationUserForm: FC<RegistrationUserFormProps> = memo((props) => {
    const { className } = props
    const { classes } = useStyles()

    const [error, setError] = useState<string>('')

    const [registerUser, { isLoading }] = useRegisterUser()

    const form = useForm<RegisterUserRequest>({
        initialValues: {
            name: '',
            first_name: '',
            last_name: '',
            country_id: 0,
        },

        validate: {
            first_name: (value) => (!value.trim() ? 'Поле имя должно быть заполненно' : null),
            last_name: (value) => (!value.trim() ? 'Поле фамилия должно быть заполненно' : null),
            country_id: (value) => (!value ? 'Страна должна быть выбрана' : null),
        },
    })

    const handlerRegister = async (values: RegisterUserRequest) => {
        try {
            await registerUser(values).unwrap()
        } catch (e: any) {
            setError(e.data.message)
        }
    }

    return (
        <form onSubmit={form.onSubmit((values) => handlerRegister(values))} style={{ marginTop: 30 }}>
            <Stack spacing="md">
                <TextInput
                    withAsterisk
                    label="Введите имя"
                    placeholder="Введите имя"
                    {...form.getInputProps('first_name')}
                />
                <TextInput
                    withAsterisk
                    label="Введите фамилию"
                    placeholder="Введите фамилию"
                    {...form.getInputProps('last_name')}
                />
                <TextInput
                    label="Название компании, если она имеется"
                    placeholder="Введите название Вашей компании"
                    {...form.getInputProps('name')}
                />
                <CountrySelect {...form.getInputProps('country_id')} />
            </Stack>
            {error && <AlertYt onClose={() => setError('')}>{error}</AlertYt>}
            <Grid mt="xl" justify="flex-end">
                <Grid.Col xs="content">
                    <Button type="submit" loading={isLoading} loaderPosition="right" fullWidth>
                        Зарегистрироваться
                    </Button>
                </Grid.Col>
            </Grid>
        </form>
    )
})

RegistrationUserForm.displayName = 'RegistrationUserForm'
