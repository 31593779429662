import { FC } from 'react'
import { ReactComponent as ExitSVG } from 'shared/assets/icons/exit.svg'
import { Button, Grid, Modal, Text } from '@mantine/core'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { useStyles } from './ExitButton.styles'

interface ExitButtonProps {
    navbar?: boolean
    page?: boolean
}

export const ExitButton: FC<ExitButtonProps> = (props) => {
    const { navbar, page } = props

    const { classes } = useStyles()

    const [opened, { open, close }] = useDisclosure(false)

    const isDesktop = useMediaQuery('(min-width: 992px)')

    return (
        <>
            {page && isDesktop && (
                <div className={classes.exitButton}>
                    <Button variant="subtle" compact className={classes.exitBtn} onClick={open}>
                        <ExitSVG className={classes.icon} />
                    </Button>
                </div>
            )}
            {navbar && !isDesktop && (
                <div className={classes.navbarBtn}>
                    <Button p={0} variant="subtle" compact className={classes.exitBtn} onClick={open}>
                        <ExitSVG className={classes.icon} /> Выход
                    </Button>
                </div>
            )}
            <Modal opened={opened} onClose={close} centered padding="lg">
                <Text align="center">Вы действительно хотите покинуть личный кабинет?</Text>
                <Grid mt="xl">
                    <Grid.Col xs={6}>
                        <Button onClick={close} fullWidth variant="outline" color="dark.7">
                            Нет
                        </Button>
                    </Grid.Col>
                    <Grid.Col xs={6}>
                        <Button
                            component="a"
                            href="https://yourtunes.net/wp-login.php?action=logout&redirect_to=https%3A%2F%2Fyourtunes.net&_wpnonce=a547c5183c"
                            color="dark"
                            fullWidth
                        >
                            Да
                        </Button>
                    </Grid.Col>
                </Grid>
            </Modal>
        </>
    )
}
