import { Select, Skeleton } from '@mantine/core'
import { FC, memo, useMemo } from 'react'
import type { SelectProps } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useGetCountries } from '../api/countryApi'
interface CountrySelectProps extends Omit<SelectProps, 'data'> {}

export const CountrySelect: FC<CountrySelectProps> = memo((props) => {
    const { ...rest } = props

    const { t } = useTranslation('certification')

    const { data: countriesDATA, isLoading: isLoadingCountries, error } = useGetCountries()

    const selectCountriesData = useMemo(() => {
        if (countriesDATA && Array.isArray(countriesDATA.result)) {
            return countriesDATA.result.map((country: any) => ({
                ...country,
                label: t(country.label),
            }))
        } else {
            return []
        }
    }, [countriesDATA, t])

    if (isLoadingCountries) {
        return <Skeleton width={'100%'} height={60} />
    }

    if (error) {
        const apiError = error as any
        if ('data' in error) {
            return <div>{`Ошибка получения стран - ${apiError.data.message}`}</div>
        }
    }

    if (countriesDATA) {
        return (
            <Select
                withAsterisk
                searchable
                clearable
                label="Выберите страну проживания"
                placeholder="Выберите страну из списка"
                data={selectCountriesData}
                {...rest}
            />
        )
    }

    return null
})

CountrySelect.displayName = 'CountrySelect'
