import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import App from 'app/App'
import ReactDOM from 'react-dom/client'
import { StoreProvider } from 'app/providers/StoreProvider'
import { I18nextProvider } from 'react-i18next'
import i18n from 'shared/configs/i18n/i18n'
import { InitializedProvider } from 'app/providers/InitializedProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <StoreProvider>
                <I18nextProvider i18n={i18n}>
                    <InitializedProvider>
                        <App />
                    </InitializedProvider>
                </I18nextProvider>
            </StoreProvider>
        </BrowserRouter>
    </React.StrictMode>
)
