import { FC, memo } from 'react'
import { LoadingOverlay } from '@mantine/core'

interface PageLoaderProps {
    type?: 'dots' | 'oval' | 'bars'
}

export const PageLoader: FC<PageLoaderProps> = memo((props) => {
    const { type = 'dots' } = props

    return <LoadingOverlay visible loaderProps={{ variant: type, size: 'xl' }} />
})

PageLoader.displayName = 'PageLoader'
