import classNames from 'classnames'
import { FC } from 'react'
import { useDisclosure } from '@mantine/hooks'
import { Avatar, Skeleton } from '@mantine/core'
import { useStyles } from './Navbar.styles'
import { MenuNavbar } from '@/widgets/Menu'
import { HamburgerButton } from '@/shared/ui/HamburgerButton/HamburgerButton'
import { useInitAuth } from '@/entities/User'

interface NavbarProps {
    className?: string
}

export const Navbar: FC<NavbarProps> = (props) => {
    const { className } = props

    const [opened, { toggle }] = useDisclosure(false)

    const { classes } = useStyles()

    const { data, isLoading } = useInitAuth()

    return (
        <div className={classNames(classes.navbar, {}, [className])}>
            <div className={classes.navbarHeader}>
                {isLoading ? (
                    <Skeleton className={classes.ava} />
                ) : (
                    <Avatar className={classes.ava} src={data?.result.ava} />
                )}
                <HamburgerButton opened={opened} onClick={toggle} />
            </div>
            <MenuNavbar opened={opened} />
        </div>
    )
}
