import { Group, Button } from '@mantine/core'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useStyles } from './NavigationButtons.styles'
interface NavigationButtonsProps {
    className?: string
    onPrev?: () => void
    onNext?: () => void
    textPrev?: string
    textNext?: string
    type?: 'button' | 'submit' | 'reset'
    isLoading?: boolean
    isPrevDisabled?: boolean
    isNextDisabled?: boolean
}

export const NavigationButtons: FC<NavigationButtonsProps> = memo((props) => {
    const { className, onPrev, isLoading, onNext, type, isPrevDisabled, isNextDisabled, textNext, textPrev } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <Group position="right" className={classNames(classes.navigationButtons, {}, [className])}>
            <Button
                fullWidth
                variant="outline"
                color="dark"
                onClick={onPrev}
                className={classes.prevBtn}
                disabled={isPrevDisabled}
            >
                {textPrev ?? 'Назад'}
            </Button>
            <Button
                fullWidth
                color="dark"
                type={type}
                loading={isLoading}
                onClick={onNext}
                className={classes.nextBtn}
                disabled={isNextDisabled}
            >
                {textNext ?? 'Далее'}
            </Button>
        </Group>
    )
})

NavigationButtons.displayName = 'NavigationButtons'
