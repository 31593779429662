import classNames from 'classnames'
import { FC, useMemo } from 'react'
import { useStyles } from './MenuList.styles'
import { MenuItem } from '../MenuItem/MenuItem'
import { MenuItemType } from '../../model/types/menu'

interface MenuListProps {
    className?: string
    dataMenuItems: MenuItemType[]
}

export const MenuList: FC<MenuListProps> = (props) => {
    const { className, dataMenuItems } = props

    const { classes } = useStyles()

    const itemsList = useMemo(
        () => dataMenuItems && dataMenuItems.map((item) => <MenuItem item={item} key={item.path} />),
        [dataMenuItems]
    )

    return <div className={classNames(classes.menuList, {}, [className])}>{itemsList}</div>
}
