import { FC, ReactNode, useCallback } from 'react'
import { MantineProvider, rem } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { useMediaQuery } from '@mantine/hooks'
import type { MantineThemeOverride } from '@mantine/core'
interface ThemeProviderProps {
    children: ReactNode
}

export const ThemeProvider: FC<ThemeProviderProps> = (props) => {
    const { children } = props

    const md = useMediaQuery('(max-width: 62em)')

    const createTheme = useCallback((): MantineThemeOverride => {
        return {
            fontFamily: 'Montserrat, sans-serif',
            defaultRadius: 'md',
            colors: {
                primary: [
                    'rgba(28, 181, 158, 0.15)',
                    '#49FFFF',
                    '#00FFFF',
                    '#00FFE1',
                    '#137567',
                    '#00A98C',
                    '#1DB6A0',
                    '#008770',
                    '#006C59',
                    '#005648',
                ],
            },
            fontSizes: {
                xs: '0.6rem',
                sm: '0.75rem',
                md: '1rem',
                lg: '1.1rem',
                xl: '1.3rem',
            },
            cursorType: 'pointer',
            primaryColor: 'primary',
            headings: {
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                sizes: {
                    h1: {
                        fontSize: rem(32),
                        lineHeight: rem(38),
                    },
                    h2: {
                        fontSize: rem(24),
                        lineHeight: rem(30),
                    },
                    h3: {
                        fontSize: rem(18),
                        lineHeight: rem(24),
                    },
                },
            },
            components: {
                InputBase: {
                    defaultProps: {
                        size: md ? 'md' : 'sm',
                    },
                },
                TextInput: {
                    defaultProps: {
                        size: md ? 'md' : 'sm',
                    },
                },
                Textarea: {
                    defaultProps: {
                        size: md ? 'md' : 'sm',
                    },
                },
                PasswordInput: {
                    defaultProps: {
                        size: md ? 'md' : 'sm',
                    },
                },
                NumberInput: {
                    defaultProps: {
                        size: md ? 'md' : 'sm',
                    },
                },
                Select: {
                    defaultProps: {
                        size: md ? 'md' : 'sm',
                    },
                },
                Switch: {
                    defaultProps: {
                        size: md ? 'md' : 'sm',
                    },
                },
                Button: {
                    defaultProps: {
                        size: md ? 'md' : 'sm',
                    },
                },
            },
        }
    }, [md])

    return (
        <MantineProvider withNormalizeCSS theme={createTheme()}>
            <Notifications />
            {children}
        </MantineProvider>
    )
}
