import { createSlice } from '@reduxjs/toolkit'
import { CertificateStateScheme } from '../types/certificateSchema'
import { certificateApi } from '../../api/certificateApi'
import { registerUserApi } from '@/features/registerUser'

const initialState: CertificateStateScheme = {
    isRegistered: true,
}

export const certificateSlice = createSlice({
    name: 'certificate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(certificateApi.endpoints.checkRegistration.matchFulfilled, (state, { payload }) => {
                state.isRegistered = payload.result.registered
            })
            .addMatcher(certificateApi.endpoints.checkRegistration.matchRejected, (state) => {
                state.isRegistered = false
            })
            .addMatcher(registerUserApi.endpoints.registerUser.matchFulfilled, (state) => {
                state.isRegistered = true
            })
    },
})

export const { actions: certificateActions } = certificateSlice
export const { reducer: certificateReducer } = certificateSlice
