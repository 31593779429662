import { FC, memo, ReactNode } from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useStyles } from './CertificateItem.styles'
import { ICertificateItem } from '../../model/types/certificateSchema'
import { CertificateStatus } from '../../model/consts/certificateConsts'
import 'dayjs/locale/ru'
interface CertificateItemProps {
    className?: string
    item?: ICertificateItem
    index?: number
    header?: boolean
    ActionMenu?: ReactNode
}

export const CertificateItem: FC<CertificateItemProps> = memo((props) => {
    const { className, item, index, ActionMenu, header } = props
    const { classes } = useStyles()

    if (header) {
        return (
            <div className={classNames(classes.certificateItem, {}, className)}>
                <div className={classes.idRow}>№</div>
                <div className={classes.NameRow}>Название</div>
                <div className={classes.statusRow}>Статус</div>
                <div className={classes.createdDateRow}>Дата создания</div>
                <div className={classes.updateDateRow}>Дата обновления</div>
                <div className={classes.actionsRow}>Действия</div>
            </div>
        )
    }

    const typeStatus: { [key: number]: CertificateStatus } = {
        1: CertificateStatus.DRAFT,
        2: CertificateStatus.APPROVING,
        3: CertificateStatus.PENDING,
        4: CertificateStatus.REGISTERED,
        5: CertificateStatus.ARCHIVED,
    }

    if (item && typeof index === 'number') {
        const { name, uid, status, created_at, updated_at } = item

        const mods = {
            [classes.draft]: status === 1,
            [classes.approving]: status === 2,
            [classes.pending]: status === 3,
            [classes.registered]: status === 4,
            [classes.archived]: status === 5,
        }

        return (
            <div className={classNames(classes.certificateItem, {}, className)}>
                <div className={classes.idRow}>{index + 1}</div>
                <div className={classes.NameRow}>{name}</div>
                <div className={classNames(classes.statusRow, mods)}>{typeStatus[status]}</div>
                <div className={classes.createdDateRow}>{dayjs(created_at).locale('ru').format('DD MMMM YYYY')}</div>
                <div className={classes.updateDateRow}>{dayjs(updated_at).locale('ru').format('DD MMMM YYYY')}</div>
                <div className={classes.actionsRow}>{ActionMenu}</div>
            </div>
        )
    }

    return null
})

CertificateItem.displayName = 'CertificateItem'
