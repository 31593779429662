import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    certificateItem: {
        display: 'flex',
        padding: '30px 10px',
        '&:hover': {
            background: theme.colors.gray[1],
            borderRadius: theme.radius.sm,
        },
        borderBottom: '1px solid',
        borderColor: theme.colors.gray[4],
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            position: 'relative',
            padding: '10px 10px',
            '& > div': {
                fontSize: theme.fontSizes.sm,
                margin: '10px 0',
            },
        },
    },
    idRow: {
        flexGrow: 1,
        maxWidth: '5%',
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },
    NameRow: {
        flexGrow: 1,
        maxWidth: '30%',
    },
    statusRow: {
        flexGrow: 1,
        maxWidth: '20%',
    },
    createdDateRow: {
        flexGrow: 1,
        maxWidth: '15%',
    },
    updateDateRow: {
        flexGrow: 1,
        maxWidth: '15%',
    },
    actionsRow: {
        flexGrow: 1,
        maxWidth: '15%',
        display: 'flex',
        justifyContent: 'center',
        [theme.fn.smallerThan('sm')]: {
            position: 'absolute',
            right: 0,
            top: '20%',
            maxWidth: 'none',
            display: 'flex',
            justifyContent: 'flex-end',
        },
    },
    draft: {
        color: '#B0B0B0',
    },
    approving: {
        color: '#4A90E2',
    },
    pending: {
        color: '#F5A623',
    },
    registered: {
        color: '#4CAF50',
    },
    archived: {
        color: '#424242',
    },
}))
