import { CountryResponse } from '../model/types/country'
import { certApi } from '@/shared/api/certApi'

const countryApi = certApi.injectEndpoints({
    endpoints: (build) => ({
        getCountries: build.query<CountryResponse, void>({
            query: () => ({
                url: '/certificate/get_countries',
            }),
            transformResponse(response: any) {
                response.result = response.result.map((types: any) => {
                    return {
                        value: types.id.toString(),
                        label: types.name,
                    }
                })

                return response
            },
        }),
    }),
})

export const useGetCountries = countryApi.useGetCountriesQuery
