import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { getIsAuth, UserRole } from '@/entities/User'
import { getAuthUrl } from '@/app/providers/InitializedProvider'
import { getIsRegistered, useCheckRegistration } from '@/entities/Certificate'
import { getRouteRegistration } from '@/shared/consts/router'
import { PageLoader } from '@/widgets/PageLoader'

interface RequireAuthProps {
    children: ReactElement
    roles?: UserRole[]
}

export function RequireAuth({ children }: RequireAuthProps) {
    const isAuth = useSelector(getIsAuth)

    const { isLoading } = useCheckRegistration()

    const isRegistered = useSelector(getIsRegistered)

    const authUrl = useSelector(getAuthUrl)

    if (isLoading) {
        return <PageLoader />
    }

    if (!isAuth) {
        const currentUrl = window.location.href
        return <Navigate to={`${authUrl}?returnUrl=${currentUrl}`} />
    }

    if (!isRegistered) {
        console.log('перенаправляем')
        return <Navigate to={getRouteRegistration()} />
    }

    return children
}
