import { Navigate } from 'react-router-dom'
import type { RouteProps } from 'react-router-dom'
import {
    getRouteCertificateDescription,
    getRouteCertificatesList,
    getRouteCertificateUpload,
    getRouteRegistration,
} from '@/shared/consts/router'
import { CertificatesListPage } from '@/pages/CertificatesListPage'
import { CertificateDescriptionPage } from '@/pages/CertificateDescriptionPage'
import { CertificateDataUploadPage } from '@/pages/CertificateDataUploadPage'
import { RegistrationPage } from '@/pages/RegistrationPage'
import { NotFoundPage } from '@/pages/NotFoundPage'
import { UserRole } from '@/entities/User/model/consts/userConsts'
export enum AppRoutes {
    CERT = 'cert',
    CERTIFICATES_LIST = 'certificates_list',
    CERTIFICATE_DESCRIPTION = 'certificate_description',
    CERTIFICATE_UPLOAD = 'certificate_upload',
    REGISTRATION = 'registration',
    NOT_FOUND_PAGE = 'not_found_page',
}

export type AppRoutesProps = RouteProps & {
    isRegistered?: boolean
    roles?: UserRole[]
}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.CERT]: '/',
    [AppRoutes.CERTIFICATES_LIST]: getRouteCertificatesList(),
    [AppRoutes.REGISTRATION]: getRouteRegistration(),
    [AppRoutes.CERTIFICATE_UPLOAD]: getRouteCertificateUpload(':uid'),
    [AppRoutes.CERTIFICATE_DESCRIPTION]: getRouteCertificateDescription(':uid?'),
    [AppRoutes.NOT_FOUND_PAGE]: '*',
}

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
    [AppRoutes.CERT]: {
        path: RoutePath[AppRoutes.CERT],
        element: <Navigate to={RoutePath.certificates_list} />,
    },
    [AppRoutes.CERTIFICATES_LIST]: {
        path: RoutePath[AppRoutes.CERTIFICATES_LIST],
        element: <CertificatesListPage />,
        isRegistered: true,
    },
    [AppRoutes.CERTIFICATE_DESCRIPTION]: {
        path: RoutePath[AppRoutes.CERTIFICATE_DESCRIPTION],
        element: <CertificateDescriptionPage />,
        isRegistered: true,
    },
    [AppRoutes.CERTIFICATE_UPLOAD]: {
        path: RoutePath[AppRoutes.CERTIFICATE_UPLOAD],
        element: <CertificateDataUploadPage />,
        isRegistered: true,
    },
    [AppRoutes.REGISTRATION]: {
        path: RoutePath[AppRoutes.REGISTRATION],
        element: <RegistrationPage />,
    },
    [AppRoutes.NOT_FOUND_PAGE]: {
        path: RoutePath[AppRoutes.NOT_FOUND_PAGE],
        element: <NotFoundPage />,
    },
}
