import classNames from 'classnames'
import { FC } from 'react'
import { Text } from '@mantine/core'
import { useStyles } from './MenuNavbar.styles'
import { MenuList } from '../MenuList/MenuList'
import { UserNameEmailCard } from '@/entities/User'
import { ExitButton } from '@/widgets/ExitButton'
import { useMainMenuItems, useSecondMenuItems } from '@/shared/configs/menuConfig/menuConfig'

interface MenuNavbarProps {
    className?: string
    opened: boolean
}

export const MenuNavbar: FC<MenuNavbarProps> = (props) => {
    const { className, opened } = props

    const { classes } = useStyles()

    const mods = {
        [classes.open]: opened,
        [classes.close]: !opened,
    }

    const modsMenuList = {
        [classes.view]: opened,
        [classes.hide]: !opened,
    }

    const mainMenuItemsList = useMainMenuItems()
    const secondaryMenuItemsList = useSecondMenuItems()

    return (
        <div className={classNames(classes.menuNavbar, mods, [className])}>
            <UserNameEmailCard className={classNames(classes.menu, modsMenuList, [classes.nameWrapper])} />
            <MenuList
                dataMenuItems={mainMenuItemsList}
                className={classNames(classes.menu, modsMenuList, [classes.mainMenu])}
            />
            <Text className={classNames('', modsMenuList)}>FAQ</Text>
            <MenuList
                dataMenuItems={secondaryMenuItemsList}
                className={classNames(classes.menu, modsMenuList, [classes.secondaryMenu])}
            />
            <div className={classNames('', modsMenuList)}>
                <ExitButton navbar={true} />
            </div>
        </div>
    )
}
