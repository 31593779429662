import classNames from 'classnames'
import { FC, memo } from 'react'
import { Avatar, Skeleton, Stack, Text } from '@mantine/core'
import { useStyles } from './UserSidebarCard.styles'
import { useInitAuth } from '@/entities/User'

interface UserSidebarCardProps {
    className?: string
}

export const UserSidebarCard: FC<UserSidebarCardProps> = memo((props) => {
    const { className } = props

    const { classes } = useStyles()

    const { data, isLoading } = useInitAuth()

    if (isLoading) {
        return (
            <div className={classes.userSidebarCard}>
                <Skeleton className={classes.ava} />
                <Stack className={className}>
                    <Skeleton w={'100%'} h={30} />
                    <Skeleton w={'100%'} h={30} />
                </Stack>
            </div>
        )
    }

    if (!data) return null

    const { display_name, email } = data.result

    return (
        <div className={classNames(classes.userSidebarCard, {}, [className])}>
            <Avatar src={data?.result.ava} className={classes.ava} />
            <Stack className={className}>
                <Text className={classes.name}>{display_name}</Text>
                <Text className={classes.email}>{email}</Text>
            </Stack>
        </div>
    )
})

UserSidebarCard.displayName = 'UserSidebarCard'
