import axios from 'axios'
import { store } from '@/app/providers/StoreProvider/config/store'

export const $api = axios.create({
    baseURL: '',
})

$api.interceptors.request.use((config) => {
    const token = store.getState().user.userToken

    if (config.headers) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})
